import React from "react"
import { graphql } from "gatsby"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import NuvoImage from "../components/NuvoImage"
import Seamless from "../components/SeamlessEmbed"
import { Section } from "../components/Layouts"

const FreeConsultation = ({ data, location, pageContext }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { language } = pageContext

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          lang={language}
        />
        <div className="free-consultations nav-spacer">
          <NuvoImage
            useAR
            publicId={post.heroImage}
            cloudName="nuvolum"
            width="auto"
            responsive
          />

          <Section>
            <div className="columns">
              <div className="column is-3" />
              <div className="column">
                <h1>{post.heading}</h1>
                <MarkdownViewer markdown={post.body} />
              </div>
              <div className="column is-3" />
            </div>
          </Section>

          <Section className="mb-0-mobile">
            <div className="columns">
              <div className="column is-3" />
              <div className="column ">
                <div className="inline-block">
                  <h5>Send Us a Message</h5>
                  <span className="required-fields">
                    <span className="required-asterisk">&nbsp; *</span> Required
                    Fields
                  </span>
                </div>
                <Seamless
                  src="https://secureform.seamlessdocs.com/f/69061d80eb868c57b9b864cab18e9c2b?embedded=true"
                  className="free-consultations__form"
                />
              </div>
              <div className="column is-3" />
            </div>
          </Section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query freeConsultationPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        body
        heroImage
      }
    }
  }
`

export default FreeConsultation
